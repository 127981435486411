@import url(https://use.typekit.net/upg6cyd.css);
html {
  box-sizing: border-box;
  background: #EEEEEE;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background-color: #E97522;
  color: white;
}

::scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: kepler-std-semicondensed-dis, serif;
  font-weight: 300;
  font-style: normal;
}

p {
  margin: 0;
}


